import * as React from "react"
import Layout from "../components/Layout"
import "../styles/common.css"
import "../styles/responsive.css"
import "@fontsource/lato"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Helmet } from "react-helmet"

const IndexPage = ({ data }) => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{data?.site?.siteMetadata?.title}</title>
    </Helmet>
    <h1 className="page-title">
      API access to your NDC content, in a simple and consitent way.
    </h1>
    <p>
      Access NDC content from your NuFlights Subscriptions, using GraphQL APIs
      to book and service Orders with provider airlines.
    </p>
    <p>
      You will need a NuFlights account for your organisation and Content
      Subscriptions to your chosen airlines.{" "}
      <a href="https://www.nuflights.com/">Contact Us</a> to get your account
      setup.
    </p>
    <p>
      To know more about NuFlights APIs, start with the{" "}
      <a href="/concepts/overview/">overview</a>.
    </p>

    {data?.airlines && (
      <section className="airlines-onboarded">
        <div className="heading-wrapper">
          <h2 className="title">NDC content providers</h2>
        </div>
        <div className="body-wrapper">
          <div className="d-flex airlines">
            {data?.airlines?.nodes.map(
              (list: any) =>
                list.childMdx && (
                  <Link
                    to={`/airlines/${list?.childMdx?.slug}`}
                    className="d-flex align-items-center airline-thumb"
                  >
                    {/* <div className="d-flex align-items-center airline-thumb"> */}
                    <div className="icon">
                      <img
                        src={list?.childMdx?.frontmatter?.icon?.publicURL}
                        alt={list?.childMdx?.frontmatter?.title}
                      />
                    </div>
                    <div className="name">
                      {list?.childMdx?.frontmatter?.title}
                    </div>
                    {/* </div> */}
                  </Link>
                )
            )}
          </div>
        </div>
      </section>
    )}

    {/* {data?.ndcUsages && (
      <section className="usage-support">
        <h2 className="title">NDC Workflows</h2>
        <div className="d-flex thumbs flex-wrap">
          {data?.ndcUsages?.nodes.map(
            (list: any) =>
              list.childMdx !== null && (
                <Link
                  to={`/references/ndc-apis/usages/${list?.childMdx?.slug}`}
                  className="thumb-wrapper"
                >
                  <div className="d-flex thumb">
                    <div className="icon">
                      <img
                        src={list?.childMdx?.frontmatter?.logo?.publicURL}
                        alt={list?.childMdx?.frontmatter?.logo?.name}
                      />
                    </div>
                    <div className="content">
                      <p className="heading">
                        {list?.childMdx?.frontmatter?.title}
                      </p>
                      <p className="info">
                        {list?.childMdx?.frontmatter?.note}
                      </p>
                    </div>
                  </div>
                </Link>
              )
          )}
        </div>
      </section>
    )} */}

    {data?.siteNavigations && (
      <div className="features">
        <div className="d-flex thumbs">
          {data?.siteNavigations?.nodes.map(
            (list: any) =>
              list.childMdx && (
                <div className="thumb-wrapper">
                  <div className="thumb">
                    <div className="d-flex align-items-center">
                      <div className="icon">
                        <img
                          src={list?.childMdx?.frontmatter?.logo?.publicURL}
                          alt={list?.childMdx?.frontmatter?.logo?.name}
                        />
                      </div>
                      <div className="content">
                        <p className="heading">
                          {list?.childMdx?.frontmatter?.title}
                        </p>
                      </div>
                    </div>

                    <p className="summary">
                      <MDXRenderer>{list?.childMdx?.body}</MDXRenderer>
                    </p>
                    <Link
                      to={list?.childMdx?.frontmatter?.link}
                      className="action"
                    >
                      {list?.childMdx?.frontmatter?.buttonText}
                    </Link>
                  </div>
                </div>
              )
          )}

          {/* <div className="thumb-wrapper">
          <div className="thumb">
            <div className="d-flex align-items-center">
              <div className="icon">
                <img src={apiIcon} alt="api" />
              </div>
              <div className="content">
                <p className="heading">Get started with NuFlights APIs</p>
              </div>
            </div>
            <p className="summary">
              We could help you setup subscriptions and quickly get you started
              here
            </p>
            <a href="" className="action">
              Get Started
            </a>
          </div>
        </div>

        <div className="thumb-wrapper">
          <div className="thumb">
            <div className="d-flex align-items-center">
              <div className="icon">
                <img src={stepsIcon} alt="step" />
              </div>
              <div className="content">
                <p className="heading">Learn through step by step guides</p>
              </div>
            </div>
            <p className="summary">
              We could help you setup subscriptions and quickly get you started
              here
            </p>
            <a href="" className="action">
              Get Started
            </a>
          </div>
        </div>

        <div className="thumb-wrapper">
          <div className="thumb">
            <div className="d-flex align-items-center">
              <div className="icon">
                <img src={researchIcon} alt="research" />
              </div>
              <div className="content">
                <p className="heading">
                  Deep Dive into APIs through References
                </p>
              </div>
            </div>
            <p className="summary">
              We could help you setup subscriptions and quickly get you started
              here
            </p>
            <a href="" className="action">
              Get Started
            </a>
          </div>
        </div>

        <div className="thumb-wrapper">
          <div className="thumb">
            <div className="d-flex align-items-center">
              <div className="icon">
                <img src={personalAssistantIcon} alt="assistent" />
              </div>
              <div className="content">
                <p className="heading">Stuck somewhere? Need Assistance</p>
              </div>
            </div>
            <p className="summary">
              We could help you setup subscriptions and quickly get you started
              here
            </p>
            <a href="" className="action">
              Get Started
            </a>
          </div>
        </div>

        <div className="thumb-wrapper">
          <div className="thumb">
            <div className="d-flex align-items-center">
              <div className="icon">
                <img src={api1Icon} alt="api1" />
              </div>
              <div className="content">
                <p className="heading">Try out our APIs</p>
              </div>
            </div>
            <p className="summary">
              We could help you setup subscriptions and quickly get you started
              here
            </p>
            <a href="" className="action">
              Get Started
            </a>
          </div>
        </div>

        <div className="thumb-wrapper">
          <div className="thumb">
            <div className="d-flex align-items-center">
              <div className="icon">
                <img src={groupChatIcon} alt="group-chat" />
              </div>
              <div className="content">
                <p className="heading">Discover more about us</p>
              </div>
            </div>
            <p className="summary">
              We could help you setup subscriptions and quickly get you started
              here
            </p>
            <a href="" className="action">
              Get Started
            </a>
          </div>
        </div> */}
        </div>
      </div>
    )}
  </Layout>
)

export default IndexPage
export const query = graphql`
  query home {
    airlines: allFile(
      filter: {
        sourceInstanceName: { eq: "airlines" }
        relativePath: { ne: "index.mdx" }
      }
    ) {
      nodes {
        childMdx {
          frontmatter {
            title
            date
            icon {
              publicURL
            }
          }
          slug
        }
      }
    }
    siteNavigations: allFile(
      filter: { sourceInstanceName: { eq: "siteNavigations" } }
      sort: { fields: childMdx___frontmatter___order }
    ) {
      nodes {
        childMdx {
          frontmatter {
            name
            title
            buttonText
            logo {
              publicURL
              name
            }
            link
            order
          }
          body
          slug
        }
      }
    }
    ndcUsages: allFile(
      filter: {
        sourceInstanceName: { eq: "ndcWorkflows" }
        childMdx: { slug: { ne: "" } }
        name: { eq: "index" }
      }
      sort: { fields: childMdx___frontmatter___order }
    ) {
      nodes {
        childMdx {
          frontmatter {
            title
            logo {
              publicURL
              name
            }
            note
            order
          }
          slug
        }
      }
    }
    site: site(siteMetadata: {}) {
      siteMetadata {
        title
      }
    }
  }
`
